import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './App.css';
import icon from './logo-icon.svg';
import logo from './logo.png';

const AnimatedContact = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [message, setMessage] = useState('');

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:hello@mediahain.de?body=${encodeURIComponent(message)}`;
  };

  const bgSpring = useSpring({
    backgroundColor: isHovered ? '#add8e6' : '#ffffff',
    config: { duration: 1000 },
  });

  return (
    <animated.div style={{ ...bgSpring, height: '100vh', width: '100vw' }} className="animated-contact">
      <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      <div className="content">
        <div
          className="logo-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={icon} alt="Logo Icon" className="logo-icon" />
          <img src={logo} alt="Logo Text" className="logo-text" />
        </div>
        <p>Contact for digital solutions at <a title="hello@mediahain.de" href="mailto:hello@mediahain.de">hello@mediahain.de</a></p>
        <p className="coming-soon">New online presence coming soon. Rate us on <a title="Schreibe eine Bewertung" href="https://de.trustpilot.com/review/mediahain.de" target="_blank" rel="noopener">Trustpilot</a>.</p>
      </div>
    </animated.div>
  );
};

export default AnimatedContact;
